import React from 'react'
import { Datagrid, DateField, TextField, ReferenceField, BooleanField } from 'react-admin'
import NonEmptyArrayField from '../../components/NonEmptyArrayField'

export const UserDatagrid = (props) => (
  <Datagrid {...props} rowClick="show" isRowSelectable={(record) => !record?.hasStoreSubscription}>
    <TextField source="id" />
    <NonEmptyArrayField source="imeiList" label="Utilisateur IMEI" />
    <TextField source="phone" label="Phone number" />
    <ReferenceField source="tenantId" reference="tenants" label="Tenant" link="show">
      <TextField source="name" />
    </ReferenceField>
    <BooleanField source="hasStoreSubscription" label="Store subscription" />
    <DateField source="lastActivity" label="Last activity" showTime />
    <DateField source="createdAt" label="Creation date" />
  </Datagrid>
)
