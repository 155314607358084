import React from 'react'
import { List, Filter, TextInput, Button, useRedirect } from 'react-admin'
import { ListActionsCreateExport } from '../../config/components'
import { UserDatagrid } from './UserDatagrid'
import { Add } from '@material-ui/icons'

const AccountFilter = (props) => (
  <Filter {...props}>
    <TextInput label="phone" source="phone" alwaysOn />
  </Filter>
)

const UserList = (props) => {
  const redirect = useRedirect()

  function goToCreateUserWithPhone() {
    redirect('create', 'users')
  }

  function goToCreateUserWithImei() {
    redirect('users/create/imei')
  }

  return (
    <>
      <List
        {...props}
        actions={
          <ListActionsCreateExport
            createButton=""
            customButtons={
              <>
                <Button startIcon={<Add />} label="Créer avec téléphone" onClick={() => goToCreateUserWithPhone()} />
                <Button startIcon={<Add />} label="Créer avec imei" onClick={() => goToCreateUserWithImei()} />
              </>
            }
          />
        }
        sort={{ field: 'createdAt', order: 'DESC' }}
        filters={<AccountFilter />}
      >
        <UserDatagrid {...props} />
      </List>
    </>
  )
}

export default UserList
