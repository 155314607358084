import React from 'react'
import { Create, SimpleForm, TextInput } from 'react-admin'

const phoneCreationValidation = (values) => {
  const errors = {}
  if (!values.phone) {
    errors.phone = 'ra.validation.required'
  }
  return errors
}

const imeiCreationValidation = (values) => {
  const errors = {}
  if (!values.mainImei) {
    errors.mainImei = 'ra.validation.required'
  }
  return errors
}

const UserCreate = (props) => {
  const isImeiCreation = window.location.hash.includes('create/imei')

  return isImeiCreation ? (
    <Create {...props}>
      <SimpleForm redirect="list" validate={imeiCreationValidation}>
        <TextInput source="mainImei" label="IMEI principal" required />
        <TextInput source="secondaryImei" label="IMEI secondaire" />
      </SimpleForm>
    </Create>
  ) : (
    <Create {...props}>
      <SimpleForm redirect="list" validate={phoneCreationValidation}>
        <TextInput source="phone" label="Téléphone" />
      </SimpleForm>
    </Create>
  )
}

export default UserCreate
