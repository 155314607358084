import React, { useState } from 'react'
import {
  Show,
  Tab,
  TabbedShowLayout,
  TextField,
  DateField,
  ReferenceManyField,
  ReferenceField,
  Datagrid,
  useRefresh,
  Button,
  DateInput,
  TextInput,
  useEditController,
} from 'react-admin'
import { ShowActionsEdit } from '../../config/components'
import { SubscriptionDatagrid } from '../subscriptions/SubscriptionDatagrid'
import { AddCircle } from '@material-ui/icons'
import Modal from '../../components/Modal'

const UserShow = (props) => {
  const { record } = useEditController(props)
  const [showCreateModal, setShowCreateModal] = useState(false)
  const refresh = useRefresh()
  const AddSubscriptionButton = () => (
    <Button label="ra.button.addFreeTrial" onClick={() => setShowCreateModal(true)}>
      <AddCircle />
    </Button>
  )

  const shouldDisplayMainImei = record && 'imeiList' in record && record.imeiList.length > 0
  const shouldDisplaySecondaryImei = shouldDisplayMainImei && record.imeiList.length > 1
  const userHasTenant = record && 'tenantId' in record && record.tenantId
  const userHasPhone = record && 'phone' in record && record.phone

  return (
    <>
      <Show actions={<ShowActionsEdit />} {...props}>
        <TabbedShowLayout>
          <Tab label="Details">
            <TextField source="id" />
            {userHasPhone && <TextField source="phone" label="Phone number" />}
            {record && 'lastActivity' in record && <DateField source="lastActivity" label="Last activity" showTime />}
            {userHasTenant && (
              <ReferenceField source="tenantId" reference="tenants" label="Tenant" link="show">
                <TextField source="name" />
              </ReferenceField>
            )}
            {shouldDisplayMainImei && <TextField source={`imeiList.0`} label="Code IMEI principal" />}
            {shouldDisplaySecondaryImei && <TextField source={`imeiList.1`} label="Code IMEI secondaire" />}
            <DateField source="createdAt" label="Creation date" />
          </Tab>
          <Tab label="Trucks">
            <ReferenceManyField reference="trucks" target="profileId" label="">
              <Datagrid>
                <ReferenceField label="Identifiant" source="id" reference="trucks" link="show">
                  <TextField source="id" />
                </ReferenceField>
                <TextField source="name" label="Name" />
              </Datagrid>
            </ReferenceManyField>
          </Tab>
          <Tab label="Favorites">
            <ReferenceManyField reference="favorites" target="profileId" label="">
              <Datagrid>
                <ReferenceField label="Identifiant" source="id" reference="favorites" link="show">
                  <TextField source="id" />
                </ReferenceField>
                <TextField source="name" label="Name" />
                <TextField source="type" label="Type" />
              </Datagrid>
            </ReferenceManyField>
          </Tab>
          {!userHasTenant && (
            <Tab label="Subscriptions">
              <AddSubscriptionButton />
              <ReferenceManyField reference="subscriptions" target="profileId" label="">
                <SubscriptionDatagrid {...props} />
              </ReferenceManyField>
            </Tab>
          )}
        </TabbedShowLayout>
      </Show>
      {!userHasTenant && (
        <Modal
          open={showCreateModal}
          title="Ajouter un essai gratuit pour cet utilisateur"
          onClose={() => {
            setShowCreateModal(false)
            refresh()
          }}
          reference={`subscriptions`}
          method="post"
        >
          <TextInput source="profileId" disabled defaultValue={props.id} />
          <DateInput
            source="expirationDate"
            label="Expiration date"
            parse={(dateString) => (dateString ? new Date(dateString).toISOString() : null)}
          />
        </Modal>
      )}
    </>
  )
}

export default UserShow
